import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import Layout from 'components/global/layout'
import MaxWidth from 'components/functional/maxWidth'
import {media} from 'utils/Media'
import Calender from 'components/book-tickets/calender'
import Close from 'svgs/close.svg'

const meta = {
    title: 'Book Tickets | The Mousetrap',
}

const TicketsLegalCopy = styled.aside`
  width: 100%;
  position: fixed;
  z-index: 999;
  right: 0;
  top: 4.25rem;
  background-color: var(--white);

  p {
    width: 90%;
    margin: 0 auto;
    padding: 0.25rem;
    text-align: center;
    width: 100%;
    height: 100%;
    color: var(--black);
    font-size: 0.85rem;
    @media ${media.md} {
      font-size: 0.95rem;
    }
  }

  @media ${media.sm} {
    top: 5rem;
  }
  @media (min-width: 541px) {
    top: 6rem;
    //541
  }
  @media ${media.md} {
    top: 5.5rem;
  }
  @media ${media.lg} {
    top: 5.85rem;
  }
`
const ThirdParyModal = styled.section`
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 40px;
    height: 40px;
    fill: var(--white);
  }

  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    animation: slide-in-bottom-again 0.85s 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    max-width: 400px;
    padding: 1rem;
    width: 90%;
    text-align: center;
    background: var(--grey);
    border-radius: 4.5px;
    position: relative;
    z-index: 5;
    @media ${media.md} {
      padding: 2.5rem;
    }

    p {
      color: var(--white);
    }

    button {
      margin-top: 1rem;

      span {
        font-size: 1rem;
      }
    }
  }
`

const BookTickets = () => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        document.body.style.setProperty('margin', '0', 'important')
    })
    return (
        <Layout title={meta.title}>
            <aside id="top" style={{position: 'absolute', top: '-50rem'}}/>
            <MaxWidth $black>
                <Calender/>
                {/* <TicketsLegalCopy>
       
      </TicketsLegalCopy> */}
            </MaxWidth>
            {open ? (
                <ThirdParyModal>
                    <Close
                        onClick={() => setOpen(false)}
                        onKeyDown={() => setOpen(false)}
                    />
                    <section>
                        <p>
                            This page and the purchase of tickets is managed by a third party
                            provider.
                        </p>
                        <div className="button">
                            <button
                                type="button"
                                className="btn btn-main"
                                onClick={() => setOpen(false)}
                            >
                                <span>Continue</span>
                            </button>
                        </div>
                    </section>
                </ThirdParyModal>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default BookTickets
