import MaxWidth from 'components/functional/maxWidth'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const CalendarStyles = styled.section`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 5rem 0;
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
    }
  }
  .text {
    text-align: center;
    margin-bottom: 2rem;
  }
`
const Calendar = () => {
  useEffect(() => {
    window.tx({
      widgetId: 'mousetrap',
      configId: 'zNuMMGUV',
      selector: '#tickx-widget',
    })
  }, []);
  return (
    <CalendarStyles>
      <h1>Book Tickets</h1>
      {/* <p className="text">You and up to 3 guests can enjoy The Mousetrap from your private Royal Box (with a private WC) and a bottle of champagne on ice, for only £499.</p> */}
      <div id="tickx-widget" />
    </CalendarStyles>
  )
}

export default Calendar
